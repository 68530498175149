<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <th rowspan="2" colspan="2">Liquidity Ratio</th>
              <th rowspan="2" class="text-center">Des 2017</th>
              <th rowspan="2" class="text-center">Des 2018</th>
              <th rowspan="2" class="text-center">Des 2019</th>
              <th rowspan="2" class="text-center">Des 2020</th>
              <th rowspan="2" class="text-center">Target</th>
              <th rowspan="2" class="text-center">Rumus</th>
            </tr>
            <tr></tr>
            <tr>
              <td rowspan="2">1</td>
              <td rowspan="2">Current Ratio</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Aktiva Lancar Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 1</td>
              <td class="text-center">Aktiva Lancar</td>
            </tr>
            <tr>
              <td class="text-center">Hutang lancar Des 2018</td>
              <td class="text-center">Hutang lancar</td>
            </tr>
            <tr>
              <td rowspan="2">2</td>
              <td rowspan="2">Cash Ratio</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Kas & Setara Kas Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.4</td>
              <td class="text-center">Kas & Setara kas</td>
            </tr>
            <tr>
              <td class="text-center">Hutang lancar Des 2018</td>
              <td class="text-center">Hutang lancar</td>
            </tr>
            <tr>
              <td rowspan="2">3</td>
              <td rowspan="2">Quick Ratio</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Kas & Setara Kas+Piutang Dagang Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 1</td>
              <td class="text-center">Kas & Setara Kas+Piutang Dagang</td>
            </tr>
            <tr>
              <td class="text-center">Hutang lancar Des 2018</td>
              <td class="text-center">Hutang lancar</td>
            </tr>
            <tr>
              <th rowspan="2" colspan="14">Solvency Ratio</th>
            </tr>
            <tr></tr>
            <tr>
              <td rowspan="2">1</td>
              <td rowspan="2">Current Ratio</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Aktiva Lancar Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&le; 0.5</td>
              <td class="text-center">Aktiva Lancar</td>
            </tr>
            <tr>
              <td class="text-center">Hutang lancar Des 2018</td>
              <td class="text-center">Hutang lancar</td>
            </tr>
            <tr>
              <td rowspan="2">2</td>
              <td rowspan="2">Cash Ratio</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Kas & Setara Kas Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&le; 1</td>
              <td class="text-center">Kas & Setara kas</td>
            </tr>
            <tr>
              <td class="text-center">Hutang lancar Des 2018</td>
              <td class="text-center">Hutang lancar</td>
            </tr>
            <tr>
              <th rowspan="2" colspan="14">Activity Ratio</th>
            </tr>
            <tr></tr>
            <tr>
              <td rowspan="2">1</td>
              <td rowspan="2">Working Capital Turnover</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Pendapatan Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 2 kali</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td class="text-center">(Aktiva Lancar-Hutang Lancar) Des 2018</td>
              <td class="text-center">Modal Kerja Bersih</td>
            </tr>
            <tr>
              <td rowspan="2">2</td>
              <td rowspan="2">Inventory Turnover</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Pendapatan Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 3 kali</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Persediaan Des 2018</td>
              <td class="text-center">Persediaan</td>
            </tr>
            <tr>
              <td rowspan="2">3</td>
              <td rowspan="2">Assets Turnover</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Pendapatan Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 1.5 kali</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Total Aktiva Des 2018</td>
              <td class="text-center">Total Aktiva</td>
            </tr>
            <tr>
              <td rowspan="2">4</td>
              <td rowspan="2">Fixed Assets Turnover</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Pendapatan Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 10 kali</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Total Aktiva Tetap Des 2018</td>
              <td class="text-center">Aktiva Tetap</td>
            </tr>
            <tr>
              <td rowspan="2">5</td>
              <td rowspan="2">Average Collection Period Ratio</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Piutang Usaha Des 2018 x 365</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&le; 90 hari</td>
              <td class="text-center">Piutang Usaha x 365</td>
            </tr>
            <tr>
              <td class="text-center">Pendapatan Bersih Des 2018</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td rowspan="2">6</td>
              <td rowspan="2">Receivable Turnover</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Pendapatan Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 4 kali</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td class="text-center">(Piutang Dagang Des 2017 + Des 2018)/2</td>
              <td class="text-center">Piutang Rata2</td>
            </tr>
            <tr>
              <th rowspan="2" colspan="14">Profitability Ratio</th>
            </tr>
            <tr></tr>
            <tr>
              <td rowspan="2">1</td>
              <td rowspan="2">Net Profit Margin</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Laba Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.06</td>
              <td class="text-center">Laba Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Pendapatan atau Penjualan Bersih Des 2018</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td rowspan="2">2</td>
              <td rowspan="2">Gross Profit Margin</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Laba Kotor Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.30</td>
              <td class="text-center">Laba Kotor</td>
            </tr>
            <tr>
              <td class="text-center">Pendapatan atau Penjualan Bersih Des 2018</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td rowspan="2">3</td>
              <td rowspan="2">Operating Margin</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Laba Usaha Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.12</td>
              <td class="text-center">Laba Usaha</td>
            </tr>
            <tr>
              <td class="text-center">Pendapatan atau Penjualan Bersih Des 2018</td>
              <td class="text-center">Pendapatan atau Penjualan Bersih</td>
            </tr>
            <tr>
              <td rowspan="2">4</td>
              <td rowspan="2">Return on Assets</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Laba Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.04</td>
              <td class="text-center">Laba Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Total Aktiva Des 2018</td>
              <td class="text-center">Total Aktiva</td>
            </tr>
            <tr>
              <td rowspan="2">5</td>
              <td rowspan="2">Return on Equity</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Laba Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.06</td>
              <td class="text-center">Laba Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Total Ekuitas Des 2018</td>
              <td class="text-center">Total Ekuitas</td>
            </tr>
            <tr>
              <td rowspan="2">6</td>
              <td rowspan="2">Rate of Return on Net Worth</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td class="text-center">Laba Bersih Des 2018</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">xxx</td>
              <td rowspan="2" class="text-center">&ge; 0.35</td>
              <td class="text-center">Laba Bersih</td>
            </tr>
            <tr>
              <td class="text-center">Modal Saham Des 2018</td>
              <td class="text-center">Modal Saham</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
